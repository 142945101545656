.create-agent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;

  &__header {
    font-weight: 400;
    font-size: 1.875rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3.125rem;
  }

  &__confirmation-pop-up {
    text-align: center;
    width: 24.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__description {
      font-size: 1rem;
      & span {
        color: #fda713;
      }
    }
    &__buttons {
      width: 100%;
      max-width: 12.5rem;
      & button,
      a {
        margin-top: 1.25rem;
      }
    }
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.9375rem 1.875rem;
  }

  &__form {
    background: #3d3f4c;
    border-radius: 1.25rem;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(7vh);

    &__input-block {
      &_generator {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      &-title {
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0.125rem;
      }
    }

    &__radio-block {
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem;
    }

    &__switchers {
      margin: 2.3125rem 0 3.5625rem 0;
      &-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 0.8125rem;
        }
      }
      &-title {
        font-weight: 400;
        font-size: 0.875rem;
        & span {
          color: #fda713;
        }
      }
      &-item {
        width: 2.1875rem;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;

    button {
      max-width: 8.75rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  &__radio {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__radio-button {
    cursor: pointer;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.125rem;

    &:last-child {
      border-radius: 0px 0.3125rem 0.3125rem 0px;
    }
  }
  &__radio-button-svg {
    width: 1.5625rem;
    min-width: 1.5625rem;
    height: 1.5625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0.625rem 0 0;
  }
}
