.player-reports-table {
  &__header {
    margin: 1.25rem 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      font-weight: 400;
      font-size: 1.375rem;
    }
  }

  &__no-results {
    font-weight: 400;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    padding: 1.25rem;
    background: #1F2232;
    border-radius: 0.625rem;
    width: 100%;
    text-align: center;
  }

  &_tab_all {
    border-top-left-radius: 0;
  }

  &__main-table-head {
    border-spacing: 0;
  }

  &__table {
    width: 100%;
    background: #3D3F4C;
    border-radius: 0.625rem;
    padding: 1.375rem 1.25rem;

    &-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &-head {
        width: 20%;
        font-weight: 400;
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #fff;
        text-align: left;
        padding: 0 0 1.0625rem 0.625rem;
        &:last-child {
          text-align: right;
          margin-right: 1.5625rem;
        }
      }
    }
  }

  &__paginator-wrapper {
    padding: 0.5938rem 1.25rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.625rem;
    border-radius: 0 0 0.625rem 0.625rem;
    background: #1F2232;
  }

  &__extra-info {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  &__page-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1.875rem;
    &-title {
      margin-right: 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: #ffffff;
      white-space: nowrap;
    }
    &-select {
      width: 5.3125rem;
    }
  }
}
