.table-row-cash {
  &__tr {
    background: #1F2232;
    border-radius: 0.625rem;
    height: 3.25rem;
    display: flex;
    padding: 0.625rem 0;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 0.3125rem;
    }
  }
  &__td {
    width: 20%;
    height: 100%;
    min-width: 10%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 0.875rem;
    padding: 0 1.25rem;
    &-el {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:not(:last-child) {
      border-right: 0.0625rem solid #3D3F4C;
    }
    &:first-child {
      justify-content: flex-start;
    }
  }

  &__arrow {
    width: 1.875rem;
    min-width: 1.875rem;
  }
}
