.country-code-selector {
  cursor: pointer;
  user-select: none;
  width: 100%;
  height: 70%;
  color: #fff;

  &__active-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0.2rem 0 0.2rem 0.625rem;
    box-sizing: border-box;
    box-sizing: border-box;
    border-bottom-left-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
    font-weight: 400;
    font-size: 0.875rem;

    &::before {
      // content: '';;
      width: 0;
      height: 0;
      border-right: 1.375rem solid transparent;
      border-left: 1.375rem solid transparent;
    }

    &_opened {
      &_open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &::before {
          border-bottom: 0.35rem solid #fff;
        }
      }
      &_close::before {
        border-top: 0.35rem solid #fff;
      }
    }
  }

  &__line {
    background: #6d6d6d;
    border-radius: 0.3125rem;
    height: 100%;
    margin-left: 0.3125rem;
    width: 1px;
    min-width: 1px;
  }
  &__additional-items {
    position: absolute;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    // background: #121931;
    border: 1px solid #fff;

    &:last-child {
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
    }
  }
  &__image {
    width: 1.875rem;
    height: 1.875rem;
    margin-right: 0.3125rem;
  }
}
