.permissions-details-for-agent-desktop {
  &__header-info {
    display: flex;
    height: 3.125rem;

    &__icon {
      width: 1.875rem;
      height: 1.875rem;
      margin: 0 0.938rem;
    }

    &:last-child {
      margin-top: 0.625rem;
    }

    &-title,
    &-username {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #ffffff;
      display: flex;
      align-items: center;

      &_capitalized {
        text-transform: capitalize;
      }
    }

    &-title {
      background: #1F2232;
      border-radius: 0.625rem;
      justify-content: center;
      padding: 0.9375rem 1.563rem;

      &_withGap {
        padding-right: 2.5rem;
      }

      &_fullWidth {
        width: 100%;
        justify-content: space-between;
      }
    }

    &-username {
      background: #f4f4f4;
      border-width: 1px 1px 1px 0px;
      border-style: solid;
      border-color: #e2e5ec;
      border-radius: 0px 5px 5px 0px;
      border-left: none;
      width: 100%;
      padding: 0.9375rem;
      border: 1px solid #e2e5ec;
    }

    &-switcher {
      width: 2.375rem;
      margin: 0 0.938rem 0 0.625rem;
      align-items: center;
      display: flex;

      &_noGap {
        margin-right: 0;
      }
    }
  }
}
