.withdrawal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__wrapper {
    background: #3d3f4c;
    border-radius: 1.25rem;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(13vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__input {
    display: flex;
    align-items: center;
    max-width: 18.75rem;
    width: 100%;
    position: relative;
    &-currency {
      position: absolute;
      right: 1.25rem;
      color: #6d6d6d;
      font-size: 1rem;
      line-height: 1rem;
      top: 1.9375rem;
    }
    & input {
      padding-right: 4.375rem;
    }
  }

  &__input-block {
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &-title {
      display: block;
      font-weight: 400;
      font-size: 1.125rem;
      margin-bottom: 0.625rem;
      text-align: center;
    }
  }

  &__header {
    font-weight: 400;
    font-size: 1.875rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3.125rem;
    &_small {
      margin-bottom: 0.625rem;
      font-size: 1.125rem;
      text-transform: none;
    }
  }
  &__top {
    width: 100%;
  }

  &__bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 3.125rem 4.375rem 0 4.375rem;
    gap: 1.25rem;

    button {
      width: fit-content;
    }
  }

  &__user-blocks {
    display: flex;
    margin-bottom: 1.875rem;
  }

  &__amount-button {
    width: min-content;
    border: 1px solid #fda713;
    padding: 0 1.25rem;
    height: 2.5rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: #fda713;
    font-size: 0.875rem;
    font-weight: 400;
    cursor: pointer;
    margin-left: 0.625rem;
  }

  &__amount-buttons {
    display: flex;
  }

  &__user-block {
    width: 100%;

    &-title {
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 0.3125rem;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.625rem 1.375rem 0.625rem 0.625rem;
      background: #1f2232;
      border-radius: 0.625rem;
    }
    &:first-child {
      margin-right: 1.875rem;
    }
    &-right {
      & span {
        display: block;
        text-align: right;
        &:first-child {
          font-weight: 400;
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.4);
          text-align: left;
        }
        &:last-child {
          font-weight: 400;
          font-size: 0.875rem;
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      display: flex;
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 1.25rem;
    }
    &-info {
      font-weight: 400;
      font-size: 0.875rem;
      &-type {
        font-size: 0.75rem;
        text-transform: uppercase;
        padding: 0.1875rem 0.375rem;
        border-radius: 0.3125rem;
        background: #0f78f2;
        width: fit-content;
        margin-top: 0.3125rem;
        &_role_6 {
          background: #0d9252;
        }
      }
    }
  }
}
