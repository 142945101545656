.create-player {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;

  &__confirmation-pop-up {
    text-align: center;
    width: 24.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__description {
      font-size: 1rem;
      & span {
        color: #fda713;
      }
    }
    &__buttons {
      width: 100%;
      max-width: 12.5rem;
      & button,
      a {
        margin-top: 1.25rem;
      }
    }
  }

  &__header {
    font-weight: 400;
    font-size: 1.875rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3.125rem;
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.9375rem 1.875rem;
  }

  &__form {
    background: #3d3f4c;
    border-radius: 1.25rem;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(13vh);

    &__input-block {
      &_generator {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      &-title {
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0.125rem;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;

    button {
      max-width: 8.75rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}
