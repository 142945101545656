.modify-agent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;

  &__header {
    font-weight: 400;
    font-size: 1.875rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3.125rem;
  }

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.9375rem 1.875rem;
  }

  &__form {
    background: #3D3F4C;
    border-radius: 1.25rem;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(13vh);

    &__input-block {
      &-title {
        font-weight: 400;
        font-size: 0.75rem;
        margin-bottom: 0.125rem;
      }
    }

    &__switchers {
      margin-top: 2.3125rem;
      &-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 0.8125rem;
        }
      }
      &-title {
        font-weight: 400;
        font-size: 0.875rem;
        & span {
          color: #FDA713;
        }
      }
      &-item {
        width: 2.1875rem;
      }
    }
  }

  &__input-date {
    height: 2.5rem; 
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;
    justify-content: center;

    button {
      display: flex;
      min-width: 5.875rem;
      max-width: 7.125rem;
      height: 2.5rem;
      padding: 0.625rem 1.25rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  &__username-block,
  &__id-block {
    display: flex;
    gap: 0.5rem;
    color: #fff;
  }

  &__user-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 1.375rem 0.625rem 0.625rem;
    background: #1F2232;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;
    &:first-child {
      margin-right: 1.875rem;
    }
    &-right {
      & span {
        display: block;
        text-align: right;
        &:first-child {
          font-weight: 400;
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.4);
          text-align: left;
        }
        &:last-child {
          font-weight: 400;
          font-size: 0.875rem;
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      display: flex;
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 1.25rem;
    }

    &-info {
      font-weight: 400;
      font-size: 0.875rem;
      &-type {
        font-size: 0.75rem;
        text-transform: uppercase;
        padding: 0.1875rem 0.375rem;
        border-radius: 0.3125rem;
        background: #0f78f2;
        width: fit-content;
        margin-top: 0.3125rem;
        &_role_6 {
          background: #0d9252;
        }
      }
    }
  }

  &__id {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    font-weight: 400;
    color: #fff !important;
  }
}
