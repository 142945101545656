.input-date-desktop {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 2.5rem;

  & .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
  }

  & .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
  & .react-datepicker__triangle {
    display: none;
  }

  &_withManualTyping {
    & .react-datepicker-wrapper {
      width: 100%;
      height: fit-content;
      z-index: 2;
    }
    & .react-datepicker__input-container {
      & input {
        background-color: transparent;
        border: none;
        color: #fff;
        width: 100%;

        box-sizing: border-box;
        // padding: 0 2.5rem 0 2.5rem;

        &:focus-visible {
          border: none;
          outline: none;
        }
      }
    }
  }

  &_disabled &__custom-date-input {
    background-color: transparent;
  }

  &_disabled {
    cursor: default;
  }

  &__custom-date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    background: #3D3F4C;
    border: 1px solid #FDA713;
    border-radius: 0.625rem;

    &_needPadding {
      padding: 0 2.625rem !important;
    }

    &-calendar {
      width: 1.5rem;
      min-width: 1.5rem;
      height: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0.3125rem 0 0;

      &_special {
        position: absolute;
        left: 0.625rem;
      }
    }
    &-value {
      margin-right: auto;
      font-size: 0.875rem;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #fff;
    }
    &-arrow {
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &_special {
        position: absolute;
        right: 0.625rem;
        z-index: 1;
      }
    }

    &_special {
      position: relative;
      z-index: 3;
    }
  }

  &_validState_1 &__custom-date-input {
    // border: 0.0625rem solid #ffffff !important;
  }
  &_validState_2 &__custom-date-input {
    border: 0.0625rem solid #0d9252 !important;
  }
  &_validState_3 &__custom-date-input {
    border: 0.0625rem solid #3D3F4C !important;
  }

  &_color_black .react-datepicker-wrapper .react-datepicker__input-container input,
  &_color_black &__custom-date-input {
    background: #3D3F4C;
  }
}
