.users {
  display: flex;
  flex-direction: column;
  width: fit-content;
  // margin-right: 1.25rem;
  width: 100%;

  &__center {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    &-controller {
      display: grid;
      justify-content: flex-end;
      grid-template-columns: auto auto;
      margin: 0 0 0 0.625rem;
    }
  }

  &__page-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 0.625rem;
    &-title {
      margin-right: 0.625rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: #ffffff;
      white-space: nowrap;
    }
    &-select {
      width: 5.3125rem;
    }
  }
}
