.filter-block-my-debt {
  display: flex;
  flex-direction: column;
  background: #3D3F4C;
  border-radius: 0.625rem;
  padding: 1.25rem;

  .days-filter {
    margin-top: 0.9375rem;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__bottom {
    display: flex;
    justify-content: end;
    margin-top: 1.25rem;
  }

  &__dates {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    font-weight: 400;
    font-size: 0.75rem;
    margin-bottom: 0.125rem;
  }

  &__date {
    display: flex;
    width: 100%;
    &-row {
      width: 100%;
      &:not(:last-child) {
        margin-right: 0.625rem;
      }
    }
    &-input {
      height: 2.5rem;
    }
  }

  &__header {
    font-weight: 400;
    font-size: 1.375rem;
    margin-bottom: 0.625rem;
  }

  &__or {
    font-weight: 400;
    font-size: 1.375rem;
    color: rgba(255, 255, 255, 0.4);
    margin: 1.25rem 5.3685rem 0;
  }

  &__button {
    display: flex;
    width: 100%;
    max-width: 11.9375rem;
  }

  &__checkbox {
    display: flex;
  }

  &__checkbox-title {
    font-weight: 400;
    font-size: 0.875rem;
    margin-left: 0.5625rem;
  }

  &__checkboxes {
    margin: 0.625rem 0;
    display: flex;
    align-items: center;

    gap: 0.625rem;

    width: 100%;
  }
}
