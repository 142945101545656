.check-box-desktop {
  user-select: none;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  flex-shrink: 0;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  transition: all 0.3s ease-in;
  border-radius: 0.25rem;
  position: relative;
  border: 0.0625rem solid rgba(255, 255, 255, 0.5);

  &_check {
    // background: #fff;
  }

  &__input {
    opacity: 0;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  &__check {
    width: 60%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
