.sidebar {
  background: #0C0F1F;
  // border-right: 0.0625rem solid #3D3F4C;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 17.5rem;
  padding: 1.25rem 0.5rem 1.25rem 1.25rem;
  z-index: 9999;
  overflow: hidden;
  width: 20%;

  &__menu {
    overflow: auto;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;

    &::-webkit-scrollbar {
      width: 4px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(41, 44, 54, 1);
      border-radius: 0.625rem;
    }
  }

  &__logo {
    // max-width: 10.5rem;
    // width: 100%;
    width: 8.7462rem;
    height: 3.8125rem;
    margin-bottom: 0.625rem;
    &-wrapper {
      display: flex;
      justify-content: center;
    }
    &_isMobile {
      max-width: 10.5rem;
      margin: 3.1875rem 0;
    }
  }
}
