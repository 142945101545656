.information {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;

  &__wrapper {
    background: #3D3F4C;
    border-radius: 1.25rem;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(13vh);
  }

  &__top {
    width: 100%;
    margin: 0 0 2.5rem;
  }

  &__header {
    font-weight: 400;
    font-size: 1.875rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3.125rem;
    color: #fff;
  }

  &__block-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.9375rem 1.875rem;
    margin-bottom: 3.125rem;
  }

  &__block {
    &-title {
      font-weight: 400;
      font-size: 0.875rem;
      margin-bottom: 0.125rem;
      color: #fff;
    }
    &-value {
      display: flex;
      font-weight: 400;
      font-size: 0.875rem;
      padding: 0.75rem 0.9375rem;
      height: 2.5rem;
      border-radius: 0.625rem;
      align-items: center;
      background: #fff;
      color: #6D6D6D;
    }
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;

    button {
      width: 5.875rem;
      height: 2.5rem;
      padding: 0.625rem 1.25rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  &__user-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;
    background: #1F2232;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;
    &:first-child {
      margin-right: 1.875rem;
    }
    &-right {
      & span {
        display: block;
        text-align: right;
        &:first-child {
          font-weight: 400;
          font-size: 0.75rem;
          color: #6D6D6D;
          text-align: left;
        }
        &:last-child {
          font-weight: 400;
          font-size: 0.875rem;
          color: #fff;
        }
      }
    }

    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      display: flex;
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 1.25rem;
    }
    &-info {
      font-weight: 400;
      font-size: 0.875rem;
      color: #fff;
      &-type {
        font-size: 0.75rem;
        text-transform: uppercase;
        padding: 0.1875rem 0.375rem;
        border-radius: 0.3125rem;
        background: #0f78f2;
        width: fit-content;
        margin-top: 0.3125rem;
        color: #fff;
        &_role_1 {
          background: #0D9252;
        }
      }
    }
  }

  &__username-block,
  &__id-block,
  &__parent-id-block {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #fff;
  }

  &__id {
    color: #fff !important;
    font-size: 0.875rem !important;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 1.375rem;
  }

  &__info-title {
    width: 100%;
    box-sizing: border-box;
    padding: 0.625rem;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    font-weight: 400;
  }
}
