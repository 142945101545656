.select {
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  user-select: none;
  background: inherit;
  border: 0.0625rem solid #fda713;
  border-radius: 0.625rem;

  &_paginator {
    border: 0.0625rem solid rgba(255, 255, 255, 0.2);
    border-radius: 0.625rem;
    color: #fff;
    background: transparent;

    .select__item {
      width: 100%;
    }
  }

  &_open {
    border-bottom: none !important;
    border-radius: 0.625rem 0.625rem 0 0 !important;
  }

  &__item {
    width: 100%;
    height: 2.5rem;
    max-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 0.625rem;
    font-size: 0.875rem;
    box-sizing: border-box;

    &_current {
      background-color: inherit;
      border-radius: 0.625rem;
    }

    &:not(&_current) {
      &:hover {
        background-color: #fda713;
      }
    }
  }

  &_color_transparent &__item_current {
    background-color: #3d3f4c;
  }

  &__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    right: 1rem;
    transition: transform 0.3s ease;
  }

  &_color {
    &_grey {
      border: 0.0625rem solid rgba(255, 255, 255, 0.2);
      border-radius: 0.625rem;
      color: #fff;
      background: transparent;
    }
  }

  &__arrow-svg {
    height: 0.571rem;
    width: 0.857rem;
  }

  &_open &__arrow {
    transform: scaleY(-1);
  }

  &__items-list {
    position: absolute;
    top: 2.125rem;
    border-radius: 0 0 0.625rem 0.625rem;
    background: #1f2232;
    z-index: 2;
    max-height: 21rem;
    box-sizing: border-box;
    border: 0.0625rem solid #fda713;
    overflow-y: auto;
    overflow-x: hidden;
    width: 101%;
    margin: 0 -1px;

    &_color {
      &_grey {
        top: 100%;
        border: 0.0625rem solid rgba(255, 255, 255, 0.2);
        background: #1f2232;
        margin: 0px -1.2px;
        width: 102.75%;
      }

      &_white {
        background: white;
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 0.5rem;
      scroll-margin-right: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 3px;
      height: 1rem;
      background: #fda713;
      cursor: pointer;
    }
  }

  &_color_transparent &__items-list {
    background-color: #3d3f4c;
  }
}
