.generate-password-button {
  width: 100%;
  height: 2.5rem;
  padding: 0.625rem 1.25rem;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.625rem;
  background: #1f2232;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &__icon {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
}
