.detail-by-player {
  width: 100%;
  min-width: fit-content;

  &__modal {
    background: #3D3F4C;
    border-radius: 0.625rem;
    max-width: 63.625rem;
    margin: 0 auto;
  }
  &__modal-header {
    font-size: 1.875rem;
    font-weight: 400;
    padding: 3.125rem 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__tabs-panel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.625rem;
    margin-bottom: 0.625rem;
    box-sizing: border-box;
    padding: 0 1.25rem;
  }
  &__tab {
    display: flex;
    height: 2.5rem;
    padding: 0.9375rem 1.875rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background: #1F2232;
    cursor: pointer;

    &_active {
      background: #FDA713;
    }
  }
}
