.subheader {
  padding: 1.2813rem 1.25rem 1.5625rem 1.875rem;
  padding-bottom: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__second-title {
    font-weight: 400;
    font-size: 1.875rem;
    color: #fff;
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    height: 2.5rem;
    width: 100%;
    & a,
    & button {
      max-width: 12.5rem;
      margin-left: 0.625rem;
    }
  }
}
