.change-password-users {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;

  &__top {
    max-width: 18.75rem;
    width: 100%;
  }

  &__pass-generator {
    width: 100%;
  }

  &__header {
    font-weight: 400;
    font-size: 1.875rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 1.0625rem;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
  }

  &__form {
    background: #3d3f4c;
    border-radius: 1.25rem;
    padding: 3.125rem 4.375rem 4.6875rem 4.375rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(13vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__input-block {
      width: 100%;

      &-title {
        font-weight: 400;
        font-size: 0.75rem;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1.25rem;
    padding: 3.125rem 0 0 0;

    button {
      width: fit-content;
      padding: 0.7188rem 1.25rem;
      // margin-left: 1.25rem;

      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  &__user-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.625rem 1.25rem 0.625rem 0.625rem;
    background: #1f2232;
    margin-bottom: 1.0625rem;
    border-radius: 0.625rem;

    &-right {
      & span {
        display: block;
        text-align: right;
        &:first-child {
          font-weight: 400;
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.4);
          text-align: left;
        }
        &:last-child {
          font-weight: 400;
          font-size: 0.875rem;
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      display: flex;
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 1.25rem;
    }
    &-info {
      font-weight: 400;
      font-size: 0.875rem;
      &-type {
        font-size: 0.75rem;
        text-transform: uppercase;
        padding: 0.1875rem 0.375rem;
        border-radius: 0.3125rem;
        background: #0f78f2;
        width: fit-content;
        margin-top: 0.3125rem;
        &_role_6 {
          background: #0d9252;
        }
      }
    }
  }
}
