.notifies-view {
  display: flex;
  flex-direction: column;
  width: 20rem;
  box-sizing: border-box;
  padding: 1.25rem 0.625rem;
  border-radius: 0.625rem;
  background-color: #1F2232;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 0.625rem;
  }

  &__title {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    font-weight: 700;
    color: #fff;
  }

  &__cross {
    cursor: pointer;
    display: flex;
    margin: 0 0.625rem 0 0;

    &-svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    &_without-margin {
      margin: 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0.625rem;
    border-radius: 0.625rem;
    background-color: #3D3F4C;

    &:not(:last-child) {
      margin: 0 0 0.625rem;
    }
  }

  &__item-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 1.5rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500;
    margin: 0 0 1px;

    &:last-child {
      min-height: auto;
    }
  }

  &__item-right,
  &__item-left {
    display: flex;
    align-items: center;
  }

  &__type {
    color: #ffb301;
  }

  &__date {
    color: #6d6d6d;
    margin: 0 0.625rem 0 0;
  }

  &__name {
    color: #fff;
    font-weight: 400;
    line-height: 1.125rem;
  }

  &__amount {
    color: #FDA713;
    font-weight: 400;
  }

  &__new {
    padding: 0.313rem;
    margin-right: 0.313rem;
    border-radius: 0.313rem;
    background: #FDA713;
  }
}
