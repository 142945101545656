.flat-page {
  &__title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.875rem;
    text-transform: capitalize;
    padding: 0 0.625rem;
    padding-bottom: 1.25rem;
  }
  &__content {
    font-weight: 400;
    font-size: 0.9375rem;
    padding: 0.625rem 1.875rem 1.25rem 1.875rem;
  }
  &__icon {
    display: flex;
    min-width: 1.875rem;
    width: 1.875rem;
    cursor: pointer;
    margin-right: 0.625rem;
  }
}
