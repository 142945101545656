.my-debt-account {
  width: 100%;
  min-width: fit-content;
  &__header {
    font-weight: 400;
    font-size: 1.375rem;
    margin: 1.25rem 0 1.25rem 0.625rem;
  }
  &__filters {
    padding: 0 0.625rem;
  }

  &__details {
    display: flex;
    width: 100%;
    gap: 0.625rem;
    padding: 0 1.25rem;
  }

  &__details-item {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    padding: 0.9375rem 1.875rem;
    border: 1px solid #fda713;
    border-radius: 0.625rem;
    background: rgba(255, 255, 255, 0.2);

    flex: 1;
  }

  &__type {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.3125rem;
  }

  &__value {
    display: flex;
    gap: 0.625rem;

    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;

    align-items: flex-end;
  }

  &__currency {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.3125rem;

    color: #6d6d6d;
    margin-bottom: 3px;
  }
}
